/* PC Component Library
 * Author: Preetha Ravichandran
 * Updated On: 11-07-2022
 *
 */
/*Imports*/
import React, {useEffect, useRef, useState } from 'react';
import { Loader ,ThemeType, toggleTheme} from '@scuf/common';
import { useParams, useNavigate } from 'react-router-dom';
import {
  IDisclaimerText,
  IEnergyCarbonGraphData
} from '../../models/ISignageViewModel';
import './SignageViewContainer.scss';
import { Theme } from '../../shared/enum';
import {
  DSIBOSecondPage,
  DSIBOThirdComponent,
  DSNotConfiguredLandscape,
  Footer,
  SlideshowFirstPage
} from '@PremiumCommercial/PremiumCommercial.pc.digitalsignage.remote';

const originalFetch = require('isomorphic-fetch');
const fetchURL = require('fetch-retry')(originalFetch);

function SignageViewContainer() {
  let siteId = '';
  const number120 = 120;
  const number1000 = 1000;
  const number60 = 60;
  const number3 = 3;
  const number2 = 2;
  const number400 = 400;
  const number404 = 404;
  const number408 = 408;
  const number500 = 500;
  const number502 = 502;
  const number503 = 503;
  const number504 = 504;
  const number522 = 522;
  const number524 = 524;
  const number10000 = 10000;
  const retryTimer = number120 * number1000;
  let deviceActivationRetryCount =0;
  let { activationCode } = useParams();
  const navigate = useNavigate();
  if (activationCode) {
    localStorage.setItem('ActivationCode', activationCode);
  }
  const [siteImage, setSiteImage] = useState('');
  const [isTemaplateAvailable, setIsTemaplateAvailable] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [screenOption, setScreenOption] = useState('Portrait');
  const [theme, setTheme] = useState('');
  const [graphDataErrorScreen, setGraphDataErrorScreen] = useState(false);
  const [errorScreen, setErrorScreen] = useState(false);
  const [activationCodeErrorScreen, setActiationCodeErrorScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [graphLoading, setGraphLoading] = useState(false);
  const [activationCodeLoading, setActivationCodeLoading] = useState(false);
  const [deviceId, setDeviceId] = useState('');
  const [deviceName, setDeviceName] = useState('');
  const [templateId, setTemplateId] = useState('');
  const [token, setToken] = useState('');
  const [energyCarbonDataConsumption, setEnergyCarbonDataConsumption] =
    useState<IEnergyCarbonGraphData>();
  const [currentComponentIndex, setCurrentComponentIndex] = useState(0);
  const [titleErrMsg, SetTitleErrMsg] =useState('Oops! Something went wrong');
  const [textErrMsg, setTextErrMsg]=useState('Something went wrong while trying to load the signage.');
  // Launch Darkly - To manage Digital Signage visibility
  const totalNoOfDays= window.ENV.DS_DATA_FOR_NO_OF_DAYS;
  const templateDataRefreshTimer = window.ENV.TEMPLATE_DATA_REFRESH_TIMER;
  const timer = window.ENV.SLIDER_TIMER;
  let displayErrorScreen:boolean=false;
  let dsLoading:boolean;

  useEffect(() => {
    const localStorageDeviceId = localStorage.getItem('deviceId');
    if (!localStorageDeviceId) {
      getTokenTemplateData('');
    } else {
      setDeviceId(localStorageDeviceId);
      getTokenTemplateData(localStorageDeviceId);
    }
  }, []);

  useEffect(()=>{
    if(!loading && !graphLoading && !activationCodeLoading && !displayErrorScreen) {
      const interval = setTimeout(() => {
        startInfiniteSlider();
      }, timer * number1000);
      return () => {
        clearInterval(interval);
      };
    }
  });


  useEffect(()  => {
    if (templateId) {
      const getProfileData = async() => {
         await getTemplateForSite(true, templateId);
      };
      getProfileData();
      getDigitalSignageGraphData(templateId, true);
      // Auto-Refresh implementation
      // octopus changes


      let interval = setInterval(() => {
        getTemplateForSite(false, templateId);
        getDigitalSignageGraphData(templateId, false);
      }, templateDataRefreshTimer * number60 * number1000);

      // clear interval
      return () => {
        clearInterval(interval);
      };
    }
  }, [templateId]);
  const usePrevious = (value: string) => {
    const ref = useRef('');
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const prevToken = usePrevious(token);
  useEffect(() => {
    if (token && token !== '' && prevToken!== token && templateId) {
      getTemplateForSite(true, templateId);
      getDigitalSignageGraphData(templateId, true);
    }
  }, [token]);


  let startDeviceActivationTimer = () => {
    const timerforTokenData =   setTimeout(() => {
      getTokenTemplateData(deviceId,false);
    }, retryTimer);
    return () => clearTimeout(timerforTokenData);
  };
  // To Disable the APIs
  const disableTheAPIs = () =>{
    setGraphLoading(false);
    setLoading(false);
  };
  const getTokenTemplateData = async (updatedDeviceId: string,tokenLoading=true) => {
    setActivationCodeLoading(tokenLoading);
    const storedActivationCode = localStorage.getItem('ActivationCode');
    let headers = {};
    if(updatedDeviceId !== '') {
      headers = {
        'X-DeviceId': updatedDeviceId
      };
    };
    try {
      const responseData = await fetch(
        `${window.ENV.BASE_URL_API_GATEWAY}/api/Signage/validateActivationCode/${storedActivationCode}`, {
         headers: headers}
      );
      if(responseData && responseData.ok)  {
        const data = await responseData.json();
        setActiationCodeErrorScreen(false);
        setDeviceId(data.deviceId);
        setDeviceName(data.deviceName);
        localStorage.setItem('deviceId', data.deviceId);
        setToken(data.token);
        setTemplateId(data.templateId);
        setLoading(true);
        setGraphLoading(true);
        setActivationCodeLoading(false);
      } else {
        setActivationCodeLoading(false);
        setActiationCodeErrorScreen(true);
        const data = await responseData.json();
        switch (data.code) {
          case 'D005': 
            disableTheAPIs();
            SetTitleErrMsg('Digital signage not available');
            setTextErrMsg('Something went wrong while trying to load this sign. \n The configuration of your digital signage is invalid.');
            break;
          case 'D012': 
            disableTheAPIs();
            SetTitleErrMsg('Digital signage expired');
            setTextErrMsg('Your digital signage has expired.');
            break;
          default: 
          SetTitleErrMsg('Oops! Something went wrong');
          setTextErrMsg('Something went wrong while trying to load the signage.');
            deviceActivationRetryCount =deviceActivationRetryCount +1;
            if(deviceActivationRetryCount<number3){
            startDeviceActivationTimer();
            } else {
              disableTheAPIs();
            }}};
    } catch (err) {
      setActivationCodeLoading(false);
      setActiationCodeErrorScreen(true);
      SetTitleErrMsg('Oops! Something went wrong');
      setTextErrMsg('Something went wrong while trying to load the signage.');      
      deviceActivationRetryCount =deviceActivationRetryCount +1;
      if(deviceActivationRetryCount<number3){
       startDeviceActivationTimer();
      } else {
        disableTheAPIs();
      }};
  };

  const startInfiniteSlider = () => {
    if (currentComponentIndex === number2) {
      return setCurrentComponentIndex(0);
    }
    return setCurrentComponentIndex(currentComponentIndex + 1);
  };
 
  const setAppTheme = (appTheme:string) => {
    if (appTheme.toLowerCase() === Theme.LIGHT.toLowerCase()) {
      toggleTheme(ThemeType.Light);
    } else {
      toggleTheme(ThemeType.Dark);
    }
  };

  const getSignageTemplateForSite = async (updatedTemplateId: string) => {
    try {
      const url =
        `${window.ENV.BASE_URL_API_GATEWAY}` +
        '/api/CompositeService/GetProfileConfiguratorDetails?templateId=' +
        updatedTemplateId;
      return await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'X-NodeId': siteId,
          'X-DeviceId':deviceId
        }
      });
    } catch (error) {
      return error;
    }
  };
  const getErrorMsg = (errMsgCode: string) => {
    let returnMsg: string = '';
    if (errMsgCode === 'DS002') {
      returnMsg = 'Digital Signage Not found';
    } else if (errMsgCode === 'DS003') {
      returnMsg = 'Digital Signage Deleted';
    } else if (errMsgCode === 'DS004') {
      returnMsg = 'Digital Signage Disabled';
    } else {
      returnMsg = 'Digital Signage not found';
    }
    return returnMsg;
  };
  const checkSiteIcon = (siteIcon: string) => {
    if (siteIcon) {
      const sitebase64 = `data:image/png;base64,${siteIcon}`;
      setSiteImage(sitebase64);
    };
  };
  
  const afterFetchResponse = async () => {
    const url =
    `${window.ENV.BASE_URL_API_GATEWAY}` +
    '/api/CompositeService/GetProfileConfiguratorDetails?templateId=' +
    templateId;
    const retyresponse = await retryFunction(url,'POST');
    if (retyresponse.isSuccess) {
      setErrorScreen(false);
      const profileConfiguratorDetails =
      retyresponse.data.profileConfiguratorDetails;
      setTheme(profileConfiguratorDetails.theme);
      setAppTheme(profileConfiguratorDetails.theme);
      if(profileConfiguratorDetails.siteTimeZone) {
        setSiteTimezone(profileConfiguratorDetails.siteTimeZone);
      }
      if (profileConfiguratorDetails.siteIcon) {
        const sitebase64 = `data:image/png;base64,${profileConfiguratorDetails.siteIcon}`;
        setSiteImage(sitebase64);
      }
      setScreenOption(profileConfiguratorDetails.screenOption);
    } else {
      setIsTemaplateAvailable(false);
      const errorMsg = getErrorMsg(retyresponse.code);
      setErrorMessage(errorMsg);
    }};

  const getTemplateForSite = async (
    loaderValue: boolean,
    templateIdForSite: string
  ) => {
    setErrorScreen(false);
    setLoading(loaderValue);
    const response: any = await getSignageTemplateForSite(templateIdForSite);
    try {
      if (response.ok) {
        setLoading(false);
        const res = await response.json();
        if (res.isSuccess) {
          const profileConfiguratorDetails =
            res.data.profileConfiguratorDetails;
          setTheme(profileConfiguratorDetails.theme);
          setAppTheme(profileConfiguratorDetails.theme);
          checkSiteIcon(profileConfiguratorDetails.siteIcon);
          setScreenOption(profileConfiguratorDetails.screenOption);
          if(profileConfiguratorDetails.siteTimeZone) {
            setSiteTimezone(profileConfiguratorDetails.siteTimeZone);
          }
           } else {
          setIsTemaplateAvailable(false);
          const errorMsg = getErrorMsg(res.code);
          setErrorMessage(errorMsg);
        }
      } else {
        const data = await response.json();
        if(data.code === 'TokenExpired') {
          getNewToken();
        }else {
          setErrorScreen(true);
          afterFetchResponse();
        }
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setErrorScreen(true);
      afterFetchResponse();
    }
  };
  const getNewToken = async () =>{
    await getTokenTemplateData(deviceId, false);
  };
  // To Get the Graph Data
  const getDigitalSignageGraphData = async (configuredTemplateId: string, loaderValue: boolean) => {
    setGraphLoading(loaderValue);
    const url = `${window.ENV.BASE_URL_API_GATEWAY}` +
      '/api/CompositeService/GetEnergyDataConsumption/' +
      configuredTemplateId;
    try {
      const responseGraphData = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          // 'X-Token': `Bearer ${token}`,
          'X-NodeId': siteId,
          'X-DeviceId': deviceId
        }
      }
      );
      if (responseGraphData.ok) {
        setGraphLoading(false);
        const res = await responseGraphData.json();
        setEnergyCarbonDataConsumption(res);
        setGraphDataErrorScreen(false);
        navigate('/');
      }else {
        const data = await responseGraphData.json();
        if(data.code === 'TokenExpired') {
          getNewToken();
        } else {
          const retyresponse = await  retryFunction(url);
            if(retyresponse.isSuccess) {
              setEnergyCarbonDataConsumption(retyresponse);
              setGraphDataErrorScreen(false);
            } else {
              setGraphDataErrorScreen(true);
            }
        }
        setGraphLoading(false);
      }
    } catch (err) {
      setGraphLoading(false);
      setGraphDataErrorScreen(true);
      const retyresponse = await  retryFunction(url);
      if(retyresponse.isSuccess) {
        setEnergyCarbonDataConsumption(retyresponse);
        setGraphDataErrorScreen(false);
      } else {
        setGraphDataErrorScreen(true);
      }
    }

  };
  const retryFunction = async(url:string,fetchMethod:string='GET')=> {
    let headers= {
        Authorization: `Bearer ${token}`,
        'X-NodeId': siteId,
        'X-DeviceId':deviceId
      };
     return   fetchURL(url, {
      method: fetchMethod,
      retries: 3,
      retryOn: [number400,number404, number408,number500,number502, number503, number504, number522, number524],
      // retryDelay: 10000,
      retryDelay: function(attempt: number,
        error: Error | null,
        response: Response | null) {
        return Math.pow(number2, attempt) * number10000; // 1000, 2000, 4000
      },
      headers: headers
    })
    .then(function(response:Response) {
      return response.json();
    }).catch(function(err:Error | null) {
      return err;
    });
  };
 
  const conditionsToCheckLoader =[!errorScreen,!activationCodeErrorScreen,!graphDataErrorScreen];
  if(conditionsToCheckLoader.every(condition => condition)) {
    displayErrorScreen = false;
  } else {
    displayErrorScreen = true;
  }
  const conditionsToCheckSlider =[!loading,!graphLoading,!activationCodeLoading];
  if(conditionsToCheckSlider.every(condition => condition)) {
    dsLoading = false;
  } else {
    dsLoading = true;
  }
  const getSiteImage = () => {
    if(siteImage) {
      return siteImage;
    } else {
      return '';
    }
  };

  const renderTemplate = () => {
    if (isTemaplateAvailable) {
        if (energyCarbonDataConsumption && !loading) {
          switch (currentComponentIndex) {
            case 0:
              return (
                <>
                <div className='ds-container'>
                    <SlideshowFirstPage
                      screenOption={screenOption}
                      timer={timer}
                      theme={theme}
                      overallReductionPercentage={
                        energyCarbonDataConsumption.overallReductionPercentage
                      }
                    ></SlideshowFirstPage>
                </div>
                <Footer siteImage={getSiteImage()} screenOption={screenOption} deviceName={deviceName} disclaimerText={IDisclaimerText.Slide1} disclaimerClassName=''></Footer>
                </>
              );
            case 1:
              return (
                <>
                <div className='ds-container'>
                  <DSIBOSecondPage
                    screenOption={screenOption}
                    timer={timer}
                    carbonBaselineWithoutIBO={
                      energyCarbonDataConsumption.carbonBaseline
                    }
                    carbonConsumptionWithIBO={
                      energyCarbonDataConsumption.carbonConsumption
                    }
                    carbonReduction={
                      energyCarbonDataConsumption.carbonReduction
                    }
                    pastCRDisplayPercentage={
                      energyCarbonDataConsumption.pastCRDisplayPercentage
                    }
                    carbonReductionIncreased={energyCarbonDataConsumption.carbonReductionIncreased}
                    energyBaselineWithoutIBO={
                      energyCarbonDataConsumption.energyBaseline
                    }
                    energyConsumptionWithIBO={
                      energyCarbonDataConsumption.energyConsumption
                    }
                    energySavings={energyCarbonDataConsumption.energySavings}
                    pastESDisplayPercentage={
                      energyCarbonDataConsumption.pastESDisplayPercentage
                    }
                    energySavingsIncrased={energyCarbonDataConsumption.energySavingsIncrased}
                    totalDays={totalNoOfDays}
                  ></DSIBOSecondPage>
                </div>
                <Footer siteImage={getSiteImage()} screenOption={screenOption} deviceName={deviceName} disclaimerText={IDisclaimerText.Slide2} disclaimerClassName=''></Footer>
                </>
              );
            case number2:
              return (
                <>
                <div className='ds-container'>
                  <DSIBOThirdComponent
                    timer={timer}
                    screenOption={screenOption}
                    galloneGas={energyCarbonDataConsumption.gallonsOfGasInTon}
                    matureTrees={energyCarbonDataConsumption.absorptionCount}
                    siteImage={siteImage}
                  />
                </div>
                <Footer siteImage={getSiteImage()} screenOption={screenOption} deviceName={deviceName} disclaimerText={IDisclaimerText.Slide3} disclaimerClassName='disclaimer-text-slide-3'></Footer>
                </>
              );
            default:
              return (
                <div></div>
              );
          }
        }
    } else {
      return (
        <DSNotConfiguredLandscape
          title={errorMessage}
          text='Please contact your facility manager for more details.'
        />
      );
    }
  };
  return (
    <>
      { displayErrorScreen && (
        <DSNotConfiguredLandscape
          title={titleErrMsg}
          text={
           `${textErrMsg}` +
            `\n` +
            `Please contact your facility manager for more details.`
          }
        />
      )}
      <Loader loading={dsLoading} text='Loading Digital Signage'>
        {!displayErrorScreen && !dsLoading && (
          <>
          {renderTemplate()}
          </>
        )}
      </Loader>
    </>
  );
}
export default SignageViewContainer;
